import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ flexBasis?: string; marginBottom?: string }>`
  display: flex;
  width: 100%;
  height: 335px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px 30px 36px;
  border: 1px solid transparent;
  cursor: pointer;
  box-shadow: 0px 0rem 0.5rem 0px #e7edf3;
  transition: 0.4s;

  &:hover {
    box-shadow: 0px 0rem 1rem 0px rgba(129, 129, 165, 0.2);
    border: 1px solid #c4dbf0;
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }

  @media (max-width: 850px) {
    height: auto;
  }
`;
const Img = styled.img`
  width: 45px;
  height: 35px;
  margin-bottom: 20px;
`;

const Title = styled(Subtitle5)`
  margin-bottom: 10px;
`;

interface SolutionComponentProps {
  title: string;
  text: string;
  cardImgSrc: string;
  className?: string;
}
const CarouselB2BEcommerceCard: React.FunctionComponent<
  SolutionComponentProps
> = ({ title, text, cardImgSrc, className }) => (
  <Wrapper className={className}>
    <div>
      <Img src={cardImgSrc} alt="card logo" />
      <Title>{title}</Title>
      <P>{text}</P>
    </div>
  </Wrapper>
);

export default CarouselB2BEcommerceCard;
