import React from 'react';
import styled from 'styled-components';
import { P } from './Typography';
import ArrowRight from '../assets/icons/arrowRight.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(82deg, #e7edf3 8.96%, #e4e6f0 104.78%);
  height: 377px;
  border-radius: 10px;
  @media (min-width: 1500px) {
    width: 250px;
  }
`;

const InsideWrapper = styled.div`
  padding: 25px 15px 22px;
`;

const ArrowIcon = styled(ArrowRight)`
  color: #8181a5;
`;

const SpaceBetweenRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #c2cedb;
`;

const BottomSection = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 202px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 26px 0px #c2cedb;
`;

const Image = styled.img`
  width: 215px;
  height: 202px;
  border-radius: 10px;
`;

const Description = styled(P)`
  color: #19385e;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

interface SolutionComponentProps {
  step: number;
  arrow: boolean;
  description: string;
  image: string;
}

const B2BEcommerceStepCard: React.FunctionComponent<SolutionComponentProps> = ({
  step,
  arrow,
  description,
  image,
}) => (
  <Wrapper>
    <InsideWrapper>
      <SpaceBetweenRow>
        <Circle>{step}</Circle>
        {arrow && <ArrowIcon />}
      </SpaceBetweenRow>
      <Description>{description}</Description>
    </InsideWrapper>
    <BottomSection>
      <Image src={image} />
    </BottomSection>
  </Wrapper>
);

export default B2BEcommerceStepCard;
