import React from 'react';
import Main from '../containers/Layout';
import b2bEcommerceStoreContent from '../../content/pages/b2b-ecommerce-store-app-for-shopify.yml';
import B2BEcommerceStoreContainer from '@/containers/toolkit/B2BEcommerceStoreContainer';

export interface B2BEcommerceStorePageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    cards: {
      title: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport3: {
    title: string;
    carouselItems: {
      title: string;
      text: string;
      image: string;
    }[];
  };
  viewport4: {
    title: string;
    subtitle: string;
    cards: {
      step: number;
      arrow: boolean;
      description: string;
      image: string;
    }[];
  };
  viewport5: {
    title: string;
    subtitle: string;
    cards: {
      title: string;
      hasFrom?: boolean;
      price: string;
      products: string;
      integrations: number;
      users: number;
      orderCondition: string;
      orders: number;
      payments: string;
    }[];
  };
  viewport6: {
    title: string;
    leftColumn: { description1: string }[];
    rightColumn: { description1: string }[];
    bottomText: string;
    button: { label: string; url: string };
  };
}

const B2BEcommerceStore: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5, viewport6 } =
    b2bEcommerceStoreContent as unknown as B2BEcommerceStorePageContent;
  return (
    <Main>
      <B2BEcommerceStoreContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
        }}
      />
    </Main>
  );
};

export default B2BEcommerceStore;
